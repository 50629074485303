import { MutableRefObject, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Grid, QuestionBoxFullWidth } from "../../../components";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
  OneLineQuestionSkeleton,
} from "../../../components/Skeleton";
import Citations from "../../../components/Citations/Citations";
import classnames from "classnames";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import { Flavour, Question, QuestionResponse } from "../../../interface";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import { motion, useInView, useScroll, useTransform } from "framer-motion"
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Pdf } from "../../../components/Pdf/pdf";
import { Video } from "../../../components/Video/video";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";

export const XThingsInspire = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
      questions,
      flavours
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      setNextTemplate: React.Dispatch<
        React.SetStateAction<Ref<HTMLDivElement>>
      >;
      nextTemplate: Ref<HTMLDivElement>;
      questions: Question[];
      flavours: Flavour[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query, assets } = response;

    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [para3Done, setPara3Done] = useState(false);
    const [header1Done, setHeader1Done] = useState(false);
    const [header2Done, setHeader2Done] = useState(false);
    const [header3Done, setHeader3Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState(false);
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const inspireXThingsRef = useRef(null);
    const questionRef = useRef<null | HTMLDivElement>(null);
    const questionIsInView = useInView(questionRef)

    useEffect(() => {
      setNextTemplate(inspireXThingsRef);
    }, []);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    // console.log(
    //   "template: 3 Parts Inspire",
    //   `height: ${document.getElementById("xthings-inspire")?.getBoundingClientRect().height}`,
    //   `width: ${document.getElementById("xthings-inspire")?.getBoundingClientRect().width}`
    // );

    //ANIMATION
    const { scrollYProgress: mainYProgress } = useScroll({
      target: inspireXThingsRef,
      offset: ["start center", "end start"],
      // offset: ["center end", "end center"],
    });

    const opacityProgress = useTransform(mainYProgress, [1, 0.6], [0.6, 1]);
    const scaleProgress = useTransform(mainYProgress, [1, 0.6], ["90%", "100%"]);
    const backgroundY = useTransform(mainYProgress, [0.6, 1], ["0%", "30%"]);

    const { scrollYProgress: contentYProgress } = useScroll({
      target: inspireXThingsRef,
    });
    const { scrollYProgress: questionYProgress } = useScroll({
      target: inspireXThingsRef,
      offset: ["center end", "end center"],
    });

    const y = useTransform(contentYProgress, [0, 1], ["1%", document.body.clientHeight < 2200 ? "-60%" : "-60%"]);
    // const y = useTransform(contentYProgress, [0, 1], ["1%", "-55%"]);
    const questionY = useTransform(questionYProgress, [1, 0], ["-100%", "0%"]);
    // END ANIMATION

    return (
      <motion.div
        ref={inspireXThingsRef}
        id="xthings-inspire"
        style={(flavours.length - 1) !== templateOrderIndex && questions.length > 1 ? { y: backgroundY, scale: scaleProgress, opacity: opacityProgress } : {}}
        className={classnames(
          "relative mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] -mt-[2.5rem] z-40",
          {
            ["bg-white !text-text-greyText"]: version === "white",
            ["bg-grey-200 !text-text-greyText"]: version === "grey",
            ["bg-grey-1000 !text-white"]: version === "dark",
          }
        )}
      >
        <div className="desktop:h-[200vh]">
          <div className="desktop:sticky desktop:top-0 desktop:h-screen overflow-hidden mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]">
            <Grid
              customClasses={classnames(
                "gap-x-4 desktop:!mx-0 relative x-inspire mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]",
                {
                  ["bg-white !text-text-greyText"]: version === "white",
                  ["bg-grey-200 !text-text-greyText"]: version === "grey",
                  ["bg-grey-1000 !text-white"]: version === "dark",
                }
              )}
            >
              <div className="mobile:col-start-1 desktop:col-start-1 mobile:col-span-full desktop:col-end-13 mobile:row-start-1 h-full desktop:min-h-screen z-30">
                {!content.imgURL ? (
                  <>
                    <div className="mobile:hidden tablet:hidden desktop:block">
                      <ImageSkeleton display="desktop" />
                    </div>
                    <div className="mobile:block desktop:hidden">
                      <ImageSkeleton display="mobile" />
                    </div>
                  </>
                ) : (
                  <>
                    {!content.imgURL ? (
                      <div className="mobile:rounded-tl-[3.125rem] desktop:rounded-tl-[1.5rem] desktop:rounded-tr-[0rem] w-full mobile:h-[450px] tablet:h-[450px] desktop:h-screen max-h-full bg-gradient-to-r from-cyan-500 to-blue-500"></div>
                    ) : (
                      <div className="relative w-full h-full">
                        <div
                          id="xthings-inspire-image-overlay"
                          className="mobile:rounded-t-3xl desktop:rounded-tl-[1.5rem] desktop:rounded-tr-[0rem] absolute top-0 left-0 w-full h-full min-h-full max-h-full bg-black/60"
                        ></div>
                        <div
                          id="xthings-inspire-main-image"
                          style={{
                            backgroundImage: `linear-gradient(
                                0deg,
                                rgba(0, 0, 0, 0) 0%,
                                rgba(0, 0, 0, 0.4) 100%
                            ),url(${content.imgURL})`,
                          }}
                          // style={{ backgroundImage: `url('${content.imgURL}')` }}
                          className={`mobile:rounded-t-3xl desktop:rounded-tl-[1.5rem] desktop:rounded-tr-[0rem] w-full mobile:h-[400px] tablet:h-[400px] desktop:h-screen desktop:min-h-screen max-h-full bg-cover bg-center bg-no-repeat`}
                        >
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div
                id="xthings-inspire-header-content"
                className="z-40 mobile:pb-[4.5rem] desktop:pb-0 desktop:flex desktop:flex-col h-full mobile:col-start-1 mobile:col-span-full desktop:col-span-10 desktop:col-start-2 mobile:row-start-1 desktop:pt-[5.25rem] mobile:pt-[2.625rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
              >
                <div className="">
                  <h2
                    id="tagline"
                    className="mobile:text-[1rem] desktop:text-lg font-medium text-white uppercase"
                  >
                    {query}
                  </h2>

                  <div className="pt-[36px]">
                    {!content.header ? (
                      <TitleSkeleton />
                    ) : (
                      <h3
                        id="xthings-inspire-header"
                        className="mobile:text-4xl desktop:text-[40px] font-bold text-white"
                      >
                        {content.header}
                      </h3>
                    )}
                  </div>
                </div>

                {/* <div
                  className={classNames("mobile:hidden desktop:block pt-16", {
                    ["desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible"]:
                      para3Done,
                    ["desktop:translate-y-full desktop:invisible desktop:h-0"]:
                      !para3Done,
                  })}
                >
                  <ButtonScrollDown customClasses="relative" versionColour="dark" />
                </div> */}
              </div>
              <motion.div
                style={useMediaQuery('(min-width: 1280px)') ? { y: y } : {}}
                id="xthings-inspire-paragraph-content"
                className="mt-[-21px] mobile:row-start-2 desktop:row-start-1 mobile:col-start-1 desktop:col-start-13 mobile:col-span-full desktop:col-end-24 flex flex-col mobile:px-8 tablet:px-[3.125rem] desktop:pt-[5.25rem] desktop:pr-8 desktop:pb-[8.5rem]"
              >
                {" "}
                {/* 134px desktop and 72px tablet and mobile padding bottom of every template for now */}
                <div className="mobile:pt-[36px] tablet:pt-[1.375rem] desktop:pt-0">
                  {!content.paragraphs[0] ? (
                    <OneLineQuestionSkeleton />
                  ) : (
                    <p className={classnames(
                      "mobile:text-xl desktop:text-2xl desktop:mb-2 mobile:mb-[36px]",
                      {
                        ["!text-text-greyHeader"]: version === "white" || version === "grey",
                        ["!text-white"]: version === "dark",
                      }
                    )}>
                      <TypewriterText
                        hasAllText
                        text={content.paragraphs[0].header}
                        type="strong"
                        isDone={header1Done}
                        setIsDone={setHeader1Done}
                      />
                    </p>
                  )}
                  {!content.paragraphs[0]?.text ? (
                    <ParagraphSkeleton />
                  ) : (
                    header1Done && (
                      <TypewriterText
                        id="xthings-inspire-paragraph-1"
                        className="mobile:text-sm desktop:text-lg font-light col-span-6"
                        hasAllText={content.paragraphs[0].done}
                        text={content.paragraphs[0].text}
                        isDone={para1Done}
                        setIsDone={setPara1Done}
                      />
                    )
                  )}
                </div>
                <div className="mobile:pt-[36px] tablet:pt-[36px] desktop:pt-[2.25rem]">
                  {!content.paragraphs[1] ? (
                    <OneLineQuestionSkeleton />
                  ) : (
                    para1Done && (
                      <p className={classnames(
                        "mobile:text-xl desktop:text-2xl desktop:mb-2 mobile:mb-[36px]",
                        {
                          ["!text-text-greyHeader"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}>
                        <TypewriterText
                          hasAllText
                          text={content.paragraphs[1].header}
                          type="strong"
                          isDone={header2Done}
                          setIsDone={setHeader2Done}
                        />
                      </p>
                    )
                  )}
                  {!content.paragraphs[1]?.text ? (
                    <ParagraphSkeleton />
                  ) : (
                    header2Done && (
                      <TypewriterText
                        id="xthings-inspire-paragraph-2"
                        className="mobile:text-sm desktop:text-lg font-light col-span-6"
                        hasAllText={content.paragraphs[1].done}
                        text={content.paragraphs[1].text}
                        isDone={para2Done}
                        setIsDone={setPara2Done}
                      />
                    )
                  )}
                </div>
                <div className="mobile:pt-[1.5rem] tablet:pt-[1.375rem] desktop:pt-[2.25rem]">
                  {!content.paragraphs[2] ? (
                    <OneLineQuestionSkeleton />
                  ) : (
                    para2Done && (
                      <p className={classnames(
                        "mobile:text-xl desktop:text-2xl desktop:mb-2 mobile:mb-[36px]",
                        {
                          ["!text-text-greyHeader"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}>
                        <TypewriterText
                          hasAllText
                          text={content.paragraphs[2].header}
                          type="strong"
                          isDone={header3Done}
                          setIsDone={setHeader3Done}
                        />
                      </p>
                    )
                  )}
                  {!content.paragraphs[2]?.text ? (
                    <ParagraphSkeleton />
                  ) : (
                    header3Done && (
                      <TypewriterText
                        id="xthings-inspire-paragraph-2"
                        className="mobile:text-sm desktop:text-lg font-light col-span-6"
                        hasAllText={content.paragraphs[2].done}
                        text={content.paragraphs[2].text}
                        isDone={para3Done}
                        setIsDone={setPara3Done}
                      />
                    )
                  )}
                </div>
                {para3Done && <Citations metadata={response.metadata} noGrid version={version} />}
                {para3Done && <MediaLinksV2 assets={assets} display="block" version={version} />}
              </motion.div>
            </Grid>
          </div>
        </div>
        <div className={classNames({ ["!hidden"]: !para3Done })}>
          <motion.div
            ref={questionRef}
            style={useMediaQuery('(min-width: 1280px)') && para3Done && followUps.length > 0 ? { y: questionY } : {}}
            className={classNames(
              "mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] w-full z-40 mobile:relative desktop:absolute mobile:-mt-[3.125rem] desktop:-mt-0",
              {
                ["hidden"]: hideQuestion,
              }
            )}>
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para3Done}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </div>
      </motion.div>
    );
  }
);
