import React from 'react';
import classnames from "classnames";
import { PDFIcon } from '../Icons/PDFIcon';
import { Download } from '../Icons';

interface PdfMeta {
  sentence: string;
  title: string;
}

interface PdfData {
  metadata: PdfMeta;
}

interface MediaAssets {
  pdf: PdfData[];
}

export const Pdf = ({
  url,
  onClick,
  name,
  version,
  assets
}: {
  url?: string;
  onClick?: () => void;
  name?: any;
  version: string;
  assets: MediaAssets,
}) => {

  return (
    <div className="flex w-full space-x-8">
      <div className="bg-pdf-image bg-contain bg-no-repeat h-[150px] mobile:w-[116px] desktop:w-[150px]" />
      <div className="flex flex-col w-full">
        <div
          className={classnames(
            "font-semibold pb-2 mobile:!text-sm tablet:!text-sm !text-text-greyText",
            {
              ["!text-text-greyText"]: version === "white" || version === "grey",
              ["!text-white"]: version === "dark",
            }
          )}
        >
          {assets?.pdf[0]?.metadata?.title}
        </div>
        <div className="flex flex-col justify-between h-full">
          <div
            className={classnames(
              "pb-2 text mobile:!text-sm tablet:!text-sm",
              {
                ["!text-text-greyText"]: version === "white" || version === "grey",
                ["!text-white"]: version === "dark",
              }
            )}
          >
            {assets?.pdf[0]?.metadata?.sentence}
          </div>
          <a
            href={url}
            target="_blank"
            className="flex mt-3 font-bold text-sm items-center focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white cursor-pointer"
            aria-label="Download PDF"
          >
            <p
              className={classnames(
                "pr-2",
                {
                  ["!text-text-greyText"]: version === "white" || version === "grey" || version === "light",
                  ["!text-white"]: version === "dark",
                }
              )}
            >
              Download
            </p>
            {version === "dark" ? (
              <Download colour="fill-white" />
            ) : (
              <Download colour="fill-black" />
            )
            }
          </a>
        </div>
      </div>
    </div>
  );
};
