import React, { useEffect } from 'react';
import { createContext, useContext, useState } from "react";
import { v4 } from "uuid";
import {
  Chunk,
  Config,
  ContextMetadataItem,
  Flavour,
  Question,
  Template,
} from "../interface";
import stream from "../utils/stream";
import processChunk from "./process-chunk";
import calculateFlavours from "./calculate-flavours";
import { useConfig } from "./config-context";
import { submitFeedback } from "./feedback";

/**
 * The way this is currently used it may as well just be a hook. It would take very little effort to adjust it.
 */

export interface ChatContextProps {
  questions: Question[];
  flavours: Flavour[]; // these match the questions e.g. questions[3] should use flavours[3]
  askQuestion: (query: string) => void;
  giveFeedback: (feedback_type: -1 | 1) => Promise<void>;
  loading: boolean;
  error: boolean;
  latestMetadata: ContextMetadataItem[];
  reset: () => void;
  resumed: boolean;
  setResumed: (value: boolean) => void;
}

const ChatContext = createContext<ChatContextProps>({
  questions: [],
  askQuestion: () => { },
  giveFeedback: async () => { },
  loading: false,
  error: false,
  latestMetadata: [],
  flavours: [],
  reset: () => { },
  resumed: false,
  setResumed: () => { },
});

const ChatProvider = (props: React.PropsWithChildren) => {
  const { config } = useConfig();
  const [controller, setController] = useState<AbortController | null>(null);
  const [conversationId, setConversationId] = useState("");
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resumed, setResumed] = useState(false);
  const [init, setInit] = useState(false);
  const [finished, setFinished] = useState(false);

  const reset = () => {
    controller?.abort();
    setQuestions([]);
    setConversationId("");
    setLoading(false);
    setError(false);
  }

  const giveFeedback = async (feedback_type: -1 | 1) => {
    const message_id = questions[questions.length - 1]?.response.messageId;
    if (!message_id) return;

    await submitFeedback({
      feedback_type,
      config,
      conversation_id: conversationId,
      message_id,
    });
  };

  console.log('finished', finished);

  const latestMetadata =
    questions[questions.length - 1]?.response?.metadata || [];

  const askQuestion = (query: string) => {
    setFinished(false);
    if (loading) return;
    setLoading(true);

    let conversation_id = conversationId;
    if (!conversation_id) {
      conversation_id = v4();
      setConversationId(conversation_id);
    }

    const seen_images = questions
      .map((q) => q.response?.content.imgURL)
      .filter((url) => url) as string[];

    let isNewQuestion = true;
    stream({
      setController,
      config,
      query,
      conversation_id,
      user_id: "test",
      is_selected_follow_up: false,
      seen_images,
      onChunk: (chunk: Chunk) => {
        setError(false);
        setQuestions((prev) => {
          if (chunk.chunk_type === "request_finish") setFinished(true);
          if (isNewQuestion) {
            isNewQuestion = false;
            const lastFollowUps =
              [...questions].reverse().find((q) => q.response?.followUps.length)?.response
                ?.followUps || [];

            return [
              // remove failed questions that aren't the current one
              ...prev.filter((q) => [
                Template.short,
                Template.long,
                Template.three_parts
              ].includes(q.response.template)),
              {
                query,
                response: processChunk(conversation_id, config, chunk, {
                  query,
                  template: Template.short, // will be overridden immediately
                  followUps: lastFollowUps,
                  userQuestion: "",
                  content: { paragraphs: [] },
                  metadata: [],
                  // @ts-ignore
                  assets: {},
                  newTopic: questions.length === 0,
                  timestamp: "",
                }),
              },
            ];
          }

          const updatedState = [...prev];
          const activeQuestionIndex = prev.length - 1;
          updatedState[activeQuestionIndex] = {
            query,
            response: processChunk(conversation_id, config, chunk, {
              ...updatedState[activeQuestionIndex].response,
            }),
          };
          return updatedState;
        });
      },
      onDone: () => setLoading(false),
      onError: () => setError(true),
    });
  };

  return (
    <ChatContext.Provider
      value={{
        questions,
        askQuestion,
        loading,
        error,
        latestMetadata,
        flavours: calculateFlavours(questions),
        giveFeedback,
        reset,
        resumed,
        setResumed,
        finished,
      }}
    >
      {props.children}
    </ChatContext.Provider>
  );
};

const useChat = () => useContext(ChatContext);

export { ChatProvider, useChat };
