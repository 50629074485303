import React from 'react';

export const Cross = ({ crossColour }: { crossColour: string }) => {
  return (
    <svg width={screen.width < 1000 ? 20 : 26} height={screen.width < 1000 ? 20 : 26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="13" fill="#F5F5F5" />
      <path d="M8 18L18 8.00004M18 18L8.00004 8" stroke="#808080" stroke-width="2" stroke-linecap="round" />
    </svg>

  );
}