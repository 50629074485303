import React from 'react';
import { MutableRefObject, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components";
import Citations from "../../../components/Citations/Citations";
import { Flavour, Question, QuestionResponse } from "../../../interface";
import {
  ImageSkeleton,
  TitleSkeleton,
  ParagraphSkeleton,
} from "../../../components/Skeleton";
import classnames from "classnames";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import { QuestionBoxFullWidth } from "../../../components/QuestionBox/QuestionBoxFullWidth";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import { motion, useInView, useScroll, useTransform } from "framer-motion"
import { Pdf } from "../../../components/Pdf/pdf";
import { Video } from "../../../components/Video/video";
import useMediaQuery from "../../../hooks/useMediaQuery";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";

export const ShortInspire = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
      questions,
      flavours
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      setNextTemplate: React.Dispatch<
        React.SetStateAction<Ref<HTMLDivElement>>
      >;
      nextTemplate: Ref<HTMLDivElement>;
      questions: Question[];
      flavours: Flavour[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState(false);
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const inspireShortRef = useRef(null);
    const questionRef = useRef<null | HTMLDivElement>(null);
    const questionIsInView = useInView(questionRef, { once: true })

    useEffect(() => {
      setNextTemplate(inspireShortRef);
    }, []);

    useEffect(() => {
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    // console.log(
    //   "template: Short Inspire",
    //   `height: ${document.getElementById("short-inspire")?.getBoundingClientRect().height}`,
    //   `width: ${document.getElementById("short-inspire")?.getBoundingClientRect().width}`
    // );

    //ANIMATION
    const { scrollYProgress: mainYProgress } = useScroll({
      target: inspireShortRef,
      offset: ["start center", "end start"],
      // offset: ["center end", "end center"],
    });

    const opacityProgress = useTransform(mainYProgress, [1, 0.6], [0.6, 1]);
    const scaleProgress = useTransform(mainYProgress, [1, 0.6], ["90%", "100%"]);
    const backgroundY = useTransform(mainYProgress, [0.6, 1], ["0%", "30%"]);

    const { scrollYProgress: contentYProgress } = useScroll({
      target: inspireShortRef,
    });
    const { scrollYProgress: questionYProgress } = useScroll({
      target: inspireShortRef,
      offset: ["center end", "end center"],
    });

    const y = useTransform(contentYProgress, [0, 1], ["1%", document.body.clientHeight < 2100 ? "-60%" : "-40%"]);
    const questionY = useTransform(questionYProgress, [1, 0], ["-100%", "0%"]);
    // const questionY = useTransform(contentYProgress, [0, 1], ["100%", "0%"]);
    // const questionY = useTransform(contentYProgress, [0, 1], ["100%", "-100%"]);

    // END ANIMATION

    return (
      <motion.div
        ref={inspireShortRef}
        id="short-inspire"
        className={classnames(
          "relative mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] -mt-[2.5rem] z-40",
          {
            ["bg-white"]: version === "white",
            ["bg-grey-200"]: version === "grey",
            ["bg-grey-1000 text-white"]: version === "dark",
          }
        )}
        style={(flavours.length - 1) !== templateOrderIndex && questions.length > 1 ? { y: backgroundY, scale: scaleProgress, opacity: opacityProgress } : {}}
      >
        <div className="desktop:h-[150vh]">
          <div className="desktop:sticky desktop:top-0 desktop:h-screen overflow-hidden mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]">
            <motion.div style={useMediaQuery('(min-width: 1280px)') ? { y: y } : {}} >
              <Grid
                testId="short-inspire-grid"
                customClasses={classnames(
                  "gap-x-4 h-full relative desktop:!mx-0 mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]",
                  {
                    ["bg-white"]: version === "white",
                    ["bg-grey-200"]: version === "grey",
                    ["bg-grey-1000 text-white"]: version === "dark",
                  }
                )}
              >
                <div
                  id="short-inspire-content"
                  className="h-full mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-12 flex flex-col"
                >
                  &nbsp;

                  <div className="h-full">
                    <div className="desktop:h-full desktop:flex desktop:flex-col desktop:justify-between mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0">
                      <div>
                        <h2
                          id="short-inspire-tagline"
                          className={classnames(
                            "mobile:text-[1rem] desktop:text-lg font-medium uppercase",
                            {
                              ["!text-text-greyHeader"]: version === "white" || version === "grey",
                              ["!text-white"]: version === "dark",
                            }
                          )}
                        >
                          {query}
                        </h2>
                        <div className="pt-[36px]">
                          {!content.header ? (
                            <TitleSkeleton />
                          ) : (
                            <h3
                              id="short-inspire-header"
                              className={classnames(
                                "mobile:text-4xl desktop:text-[40px] font-normal",
                                {
                                  ["!text-text-greyHeader"]: version === "white" || version === "grey",
                                  ["!text-white"]: version === "dark",
                                }
                              )}
                            >
                              {content.header}
                            </h3>
                          )}
                        </div>
                        <div
                          id="short-inspire-paragraph-content-1"
                          className="mobile:pt-[2.25rem] desktop:pt-[2.75rem]"
                        >
                          {!content.paragraphs[0] ? (
                            <>
                              <div className="mobile:block tablet:hidden desktop:block">
                                <ParagraphSkeleton />
                              </div>
                              <div className="mobile:hidden tablet:block desktop:hidden">
                                <ParagraphSkeleton lines="tablet" />
                              </div>
                            </>
                          ) : (
                            <TypewriterText
                              id="short-inspire-paragraph-1"
                              className="mobile:text-sm desktop:text-lg font-light"
                              hasAllText={content.paragraphs[0].done}
                              text={content.paragraphs[0].text}
                              isDone={para1Done}
                              setIsDone={setPara1Done}
                            />
                          )}
                        </div>
                      </div>
                      {/* <div
                        className={classNames("mobile:hidden desktop:block absolute bottom-16", {
                          ["desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible"]:
                            para1Done,
                          ["desktop:translate-y-full desktop:invisible desktop:h-0"]:
                            !para1Done,
                        })}
                      >
                        {version === "white" && (
                          <ButtonScrollDown
                            customClasses="relative"
                            versionColour="white"
                          />
                        )}
                        {version === "dark" && (
                          <ButtonScrollDown
                            customClasses="relative"
                            versionColour="dark"
                          />
                        )}
                        {version === "grey" && (
                          <ButtonScrollDown
                            customClasses="relative"
                            versionColour="grey"
                          />
                        )}
                      </div> */}
                    </div>

                    <div className="mobile:pt-[36px] desktop:pt-0">
                      {!content.imgURL ? (
                        <ImageSkeleton display="mobile" />
                      ) : (
                        <div
                          style={{ backgroundImage: `url('${content.imgURL}')` }}
                          className="w-full max-h-[450px] mobile:h-[500px] tablet:h-[450px] bg-no-repeat desktop:hidden bg-cover bg-center"
                        ></div>
                      )}
                    </div>
                  </div >
                </div >

                {/* Desktop only image */}
                < div
                  id="short-inspire-desktop-image-container"
                  className="desktop:col-start-13 mobile:hidden desktop:block desktop:col-span-12 w-full h-full row-start-1 z-30"
                >
                  {!content.imgURL ? (
                    <ImageSkeleton display="desktop" />
                  ) : (
                    <>
                      {!content.imgURL ? (
                        <div className="w-full h-screen max-h-full bg-gradient-to-r from-cyan-500 to-blue-500"></div>
                      ) : (
                        <div
                          id="short-inspire-desktop-image"
                          className="pt-[144px] pr-[96px]"
                        // style={{ backgroundImage: `url('${content.imgURL}')` }}
                        // className={`w-full h-full min-h-full max-h-full bg-cover bg-center bg-no-repeat mobile:rounded-tr-[3.125rem] desktop:rounded-tr-[1.5rem]`}
                        >
                          <img src={content.imgURL} />
                        </div>
                      )}
                    </>
                  )}
                </div >
              </Grid >
              {para1Done &&
                <div className={classnames(
                  {
                    ["bg-white"]: version === "white",
                    ["bg-grey-200 mt-[-1px]"]: version === "grey",
                    ["bg-grey-1000 text-white"]: version === "dark",
                  }
                )}>
                  <Citations metadata={response.metadata} version={version} />
                  <MediaLinksV2 assets={assets} display="fullwidth" version={version} />
                </div>
              }
            </motion.div>
          </div >
        </div >
        <div className={classNames({ ["!hidden"]: !para1Done })}>
          <motion.div
            ref={questionRef}
            id="question-container"
            style={useMediaQuery('(min-width: 1280px)') && para1Done && followUps.length > 0 ? { y: questionY } : {}}
            className={classNames(
              "w-full mobile:relative desktop:absolute z-40 mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] mobile:-mt-[3.125rem] desktop:-mt-0",
              {
                ["hidden"]: hideQuestion,
              }
            )}
          >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para1Done}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </div>
      </motion.div >
    );
  }
);
