import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const ThemeIBM = React.lazy(() => import('./ThemeIBM'));

const ThemeSelector: React.FC = ({ children } : any) => {
  const [shouldRenderThemeIBM, setShouldRenderThemeIBM] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('client')
  console.log(page);

  useEffect(() => {
    if (page?.toLowerCase() === "ibm") {
      setShouldRenderThemeIBM(true);
    } 
  }, [])
  
  return (
    <>      
      <React.Suspense>
        {shouldRenderThemeIBM && <ThemeIBM />}      
      </React.Suspense>
      {children}
    </>
  )
};

export default ThemeSelector;
