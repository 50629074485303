import { useState, useEffect, createElement } from "react";
import { useChat } from '../../context/chat-context';
/* Typewriter text component
 * @param text - The text to display, this can be updated continuously (i.e. a stream)
 * @param type - The element type to display the text inside
 * @param classNames - Any additional classNames to add to the element
 * @param showCursor - Show the blinking cursor or not (animation in CSS)
 * @param typeDelay - Delay between typing each letter
 * @param endDelay - Delay between finishing typing and hiding the cursor
 * */
export function TypewriterText({
  text = "",
  type = "p",
  className = "",
  showCursor = true,
  typeDelay = 1,
  endDelay = 1000,
  id,
  isDone,
  setIsDone,
  hasAllText,
}: {
  id?: string;
  text?: string;
  type?: string;
  showCursor?: boolean;
  className?: string;
  typeDelay?: number;
  endDelay?: number;
  isDone: boolean;
  hasAllText: boolean;
  setIsDone: (val: boolean) => void;
}) {
  const [writtenText, setWrittenText] = useState("");
  const { resumed } = useChat();

  useEffect(() => {
    const newLength = writtenText.length + 1;

    if (hasAllText && newLength === text.length) {
      setIsDone(true);
    }

    const timeout = setTimeout(() => {
      setWrittenText(text.slice(0, newLength));
    }, typeDelay);

    return () => clearTimeout(timeout);
  }, [text, writtenText, typeDelay]);

  const elementClassNames =
    !isDone && showCursor
      ? [className, "blinking-cursor"].join(" ").trim()
      : className.trim();

  if (resumed) return createElement(type, { className: elementClassNames, id, "data-testid": "typewriter" }, text);
  return createElement(type, { className: elementClassNames, id, "data-testid": "typewriter" }, writtenText);
}

export default TypewriterText;