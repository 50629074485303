import { createBrowserRouter } from "react-router-dom";
import { Root } from "./root";
import MainApp from "./pages/MainApp/MainApp";
import Streaming from "./pages/Streaming/Streaming";
import Initial from "./pages/Initial/Initial";
import VideoTest from "./pages/VideoTest";
import "./App.css";
import ThemeSelector from "./ThemeSelector";

export const router = createBrowserRouter([
  {
    path: "/templates",
    element: (
      <Root>
        <ThemeSelector>
          <MainApp />
        </ThemeSelector>
      </Root>
    ),
  },
  {
    path: "/streaming",
    element: (
      <Root>
         <ThemeSelector>
          <Streaming />
         </ThemeSelector>
      </Root>
    ),
  },
  {
    path: "/",
    element: (
      <Root>
         <ThemeSelector>
          <Initial />
         </ThemeSelector>
      </Root>
    ),
  },
  {
    path: "/entry",
    element: (
      <Root>
        <ThemeSelector>
          <Initial />
        </ThemeSelector>
      </Root>
    ),
  },
  {
    path: "/video-test",
    element: (
      <Root>
        <ThemeSelector>
          <VideoTest />
        </ThemeSelector>
      </Root>
    ),
  },
]);
