import { useNavigate } from "react-router-dom";
import { MovingSpinningCircles } from "../../components/Icons";
import { TypewriterText } from "../../components";
import React, { useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { useConfig } from "../../context/config-context";
import { useChat } from '../../context/chat-context';

export default function Initial() {
  const navigate = useNavigate();
  const { config } = useConfig();
  const { questions, setResumed } = useChat();
  const [expanded, setExpanded] = useState(false);
  const [textIsDone, setTextIsDone] = useState(false);
  const [iFrameExpanded, setIFrameExpanded] = useState(false);
  const pillRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    console.log('config', config.HOST)
    const listener = (event: MessageEvent) => {
      console.log(event);
      if (event.origin !== config.HOST) {
        return;
      }

      if (event.data === "OPEN") {
        setIFrameExpanded(true);
        setTimeout(() => {            
            setExpanded(true);
        }, 100);      

        setTimeout(() => {
          navigate("/streaming");
        }, 600);
      }
    };

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, [navigate]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (pillRef.current) {
      observer.observe(pillRef.current);
    }

    return () => {
      pillRef.current && observer.unobserve(pillRef.current);
    };
  }, []);

  return (
    <div
      className={classnames(
        "w-full h-screen justify-left flex items-end overflow-hidden",
        {
          "justify-left flex items-end bg-black/50 backdrop-blur-md transition-all duration-1000 desktop:-pr-50px":
            expanded,
        }
      )}
    >
      <button
        className={classnames(
          "flex relative content-center items-center opacity-80 z-50 cursor-pointer",
          {
            "transition-all duration-500 justify-center bg-[#000000] text-white mobile:h-screen smallscreenheight:h-screen tablet:h-[550px] desktop:w-[calc(100%-100px)] w-full inset-x-0 desktop:px-[122px] desktop:py-[85px] desktop:mx-[50px] flex-col p-[5%] rounded-t-[50px]":
              expanded,
            " bg-[#000000] text-white h-[78px] w-[380px] p-4 backdrop-blur-[20px] rounded-[1.5rem] ml-[30px] desktop:mb-[30px] tablet:mb-[44px] mobile:mb-[49px]":
              iFrameExpanded && !expanded,
            " bg-[#000000] text-white p-4 backdrop-blur-[20px] w-full h-full rounded-t-[42px] rounded-br-[42px] text-left":
              !iFrameExpanded,
          }
        )}
      >
        <div
          ref={pillRef}
          className={classnames(
            "flex relative content-center items-center opacity-80 z-50 cursor-pointer",
            {
              "transition-all duration-500 justify-center bg-[#000000] text-white mobile:h-screen smallscreenheight:h-screen tablet:h-[550px] desktop:w-[calc(100%-100px)] w-full inset-x-0 desktop:px-[122px] desktop:py-[85px] desktop:mx-[50px] flex-col p-[5%] rounded-t-[50px]":
                expanded,
              " bg-[#000000] text-white h-[78px] w-[380px] p-4 backdrop-blur-[20px] rounded-[1.5rem] ml-[30px] desktop:mb-[30px] tablet:mb-[44px] mobile:mb-[49px]":
                iFrameExpanded && !expanded,
              " bg-[#000000] text-white p-4 backdrop-blur-[20px] w-full h-full rounded-t-[42px] rounded-br-[42px] text-left":
                !iFrameExpanded,
            }
          )}
        >
          <div
            className={classnames("flex ml-[5px] mt-[20px] justify-left", {
              block: !expanded,
              hidden: expanded,
            })}
          >
            <MovingSpinningCircles />
          </div>
          <TypewriterText
            id="initial"
            isDone={textIsDone}
            setIsDone={setTextIsDone}
            className={classnames("text-base", {
              block: !expanded,
              hidden: expanded,
            })}
            hasAllText
            text={
              width > 375
                ? "Hello! I'm a conversational AI. How can I help you today?"
                : "Hello! How can I help you today?"
            }
          />
        </div>
      </button>
    </div>
  );
}
