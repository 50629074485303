import { Config } from "../interface";

export async function submitFeedback({
  config,
  feedback_type,
  conversation_id,
  message_id,
}: {
  config: Config;
  feedback_type: number;
  conversation_id: string;
  message_id: string;
}) {
  try {
    await fetch(
      `${config.SATALIA_HOST}/chat/report?client_id=${config.CLIENT_ID}&site_id=${config.SITE_ID}`,
      {
        method: "POST",
        headers: {
          "Api-Key": config.SATALIA_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          conversation_id,
          message_id,
          event_type: "feedback",
          feedback_type,
        }),
      }
    );
  } catch (e) {
    console.log(e);
  }
}
