import React from 'react';
import classnames from "classnames";

export const ParagraphSkeleton = ({ lines = 5, classNames }: { lines?: number | "default" | "one" | "tablet" | "subheading", classNames?: string }) => {
  return (
    <div role="status" className="animate-pulse">
      {typeof lines === 'number' && (
        <>
          <div className={classnames("h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2.5", classNames)}></div>
          {lines >= 2 && <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-2.5"></div>}
          {lines >= 3 && <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-2.5"></div>}
          {lines >= 4 && <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-2/4 mb-2.5"></div>}
          {lines >= 5 && <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>}
          {lines >= 6 && <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-2.5"></div>}
          {lines >= 7 && <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-2.5"></div>}
          {lines >= 8 && <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-2.5"></div>}
          {lines >= 9 && <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-2.5"></div>}
          {lines >= 10 && <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-2.5"></div>}
        </>
      )}
      {lines === 'default' && (
        <div className={classNames}>
          <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2.5"></div>
          <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-2.5"></div>
          <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-2.5"></div>
          <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-2/4 mb-2.5"></div>
          <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-full"></div>
        </div>
      )}
      {lines === "one" && (
        <div className={`h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2.5 ${classNames}`}></div>
      )}
      {lines === "tablet" && (
        <div className={classNames}>
          <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2.5"></div>
          <div className="h-[1.063rem] bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-8"></div>
        </div>
      )}
      {lines === "subheading" && (
        <div className={classNames}>
          <div className="h-[1.563rem] bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2.5"></div>
          <div className="h-[1.563rem] bg-gray-200 rounded-full dark:bg-gray-700 w-4/5 mb-8"></div>
        </div>
      )}
      <span className="sr-only">Loading...</span>
    </div>
  );
};
