/* istanbul ignore file */
export const templateArray = [
  "LongInform",
  "ShortInform",
  "XThingsInform",
  "LongInspire",
  "ShortInspire",
  "XThingsInspire",
  "ShortHero",
  "LongHero",
  "XThingsHero",
  "Single",
  "VideoHero"
];
