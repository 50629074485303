import React from 'react';

export const QuestionSkeletonFullWidth = () => (
  <div role="status" className="animate-pulse">
    <div className="flex">
      <div className="h-12 bg-gray-200 rounded-full dark:bg-gray-700 w-1/4 mr-3.5"></div>
      <div className="h-12 bg-gray-200 rounded-full dark:bg-gray-700 w-1/4 mr-3.5"></div>
      <div className="h-12 bg-gray-200 rounded-full dark:bg-gray-700 w-1/4"></div>
    </div>
    <span className="sr-only">Loading...</span>
  </div>
);
