import { Config } from "./interface";

const config: { [client: string]: Config } = {
  wpp: {
    SITE_ID: "wpp_com",
    CLIENT_ID: "wpp",
    HOST: "https://gxmwpp.wpenginepowered.com",
    // ### TO GET IFRAME WORKING LOCALLY COMMENT OUT LINE ABOVE AND REINSTATE LINE BELOW
    // HOST: "http://localhost",
    SATALIA_HOST: "https://api.stg.prod.audiencebrains.satalia.io",
    SATALIA_API_KEY: "MTkxMGU3YjMtYTQ1Ny03ZTBhLTc4N2MtZmI3ODcxZDE0OTll",
    INITIAL_QUESTIONS: [
      "How is WPP leading in AI innovation?",
      "What is WPP's commitment to net zero?",
      "How is WPP building a better future for our communities?",
    ],
    BRAND_NAME: "WPP",
  },
  wpp_uat: {
    SITE_ID: "wpp_com",
    CLIENT_ID: "wpp",
    HOST: "https://cm.wpp.com/",
    // ### TO GET IFRAME WORKING LOCALLY COMMENT OUT LINE ABOVE AND REINSTATE LINE BELOW
    // HOST: "http://localhost",
    SATALIA_HOST: "https://api.stg.prod.audiencebrains.satalia.io",
    SATALIA_API_KEY: "57dcea54-a3c6-4303-8215-11e457dc77a0",
    INITIAL_QUESTIONS: [
      "How is WPP leading in AI innovation?",
      "What is WPP's commitment to net zero?",
      "How is WPP building a better future for our communities?",
    ],
    BRAND_NAME: "WPP",
  },
  wpp_open: {
    SITE_ID: "wpp_com",
    CLIENT_ID: "wpp",
    HOST: "https://gxmwpp.wpenginepowered.com",
    // ### TO GET IFRAME WORKING LOCALLY COMMENT OUT LINE ABOVE AND REINSTATE LINE BELOW
    // HOST: "http://localhost",
    SATALIA_HOST: "https://api.stg.prod.audiencebrains.satalia.io",
    SATALIA_API_KEY: "MTkxMGU3YjMtYTQ1Ny03ZTBhLTc4N2MtZmI3ODcxZDE0OTll",
    INITIAL_QUESTIONS: [
      "How is WPP leading in AI innovation?",
      "What is WPP's commitment to net zero?",
      "How is WPP building a better future for our communities?",
    ],
    BRAND_NAME: "WPP",
  },
  ibm: {
    SITE_ID: "ibm_com",
    CLIENT_ID: "ibm",
    HOST: "https://gxmwpp.wpenginepowered.com",
    // ### TO GET IFRAME WORKING LOCALLY COMMENT OUT LINE ABOVE AND REINSTATE LINE BELOW
    // HOST: "http://localhost",
    SATALIA_HOST: "https://api.stg.prod.audiencebrains.satalia.io",
    SATALIA_API_KEY: "MTkxMGU3YjMtYTQ1Ny03ZTBhLTc4N2MtZmI3ODcxZDE0OTll",
    INITIAL_QUESTIONS: [
      "Tell me about the IBM Watson platform",
      "What are the benefits of blockchain?",
      "How is the automotive industry using IBM?",
    ],
    BRAND_NAME: "IBM",
  },
};

export default config;
