import React, { MutableRefObject, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Grid } from "../../../components";
import { Flavour, Question, QuestionResponse } from "../../../interface";
import {
  ParagraphSkeleton,
  ImageSkeleton,
  TitleSkeleton,
} from "../../../components/Skeleton";
import classnames from "classnames";
import { QuestionBoxFullWidth } from "../../../components/QuestionBox/QuestionBoxFullWidth";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import { motion, useScroll, useTransform, useInView, circOut, cubicBezier, useMotionValueEvent } from "framer-motion"
import Citations from "../../../components/Citations/Citations";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import { Pdf } from "../../../components/Pdf/pdf";
import { Video } from "../../../components/Video/video";
import useMediaQuery from "../../../hooks/useMediaQuery";

export const ShortInform = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
      questions,
      flavours
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      setNextTemplate: React.Dispatch<
        React.SetStateAction<Ref<HTMLDivElement>>
      >;
      nextTemplate: Ref<HTMLDivElement>;
      questions: Question[];
      flavours: Flavour[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState(false);
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const informShortRef = useRef(null);
    const questionRef = useRef<null | HTMLDivElement>(null);
    const questionIsInView = useInView(questionRef);

    useEffect(() => {
      setNextTemplate(informShortRef);
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);


    //ANIMATION
    const { scrollYProgress: mainYProgress } = useScroll({
      target: informShortRef,
      offset: ["start center", "end start"],
      // offset: ["center end", "end center"],
    });

    const opacityProgress = useTransform(mainYProgress, [1, 0.6], [0.6, 1]);
    const scaleProgress = useTransform(mainYProgress, [1, 0.6], ["90%", "100%"]);
    const backgroundY = useTransform(mainYProgress, [0.6, 1], ["0%", "30%"]);

    const { scrollYProgress: contentYProgress } = useScroll({
      target: informShortRef,
    });
    const { scrollYProgress: questionYProgress } = useScroll({
      target: informShortRef,
      offset: ["center end", "end center"],
    });

    const y = useTransform(contentYProgress, [0, 1], ["1%", document.body.clientHeight < 2200 ? "-60%" : "-40%"]);
    const questionY = useTransform(questionYProgress, [1, 0], ["-100%", "0%"]);

    //END ANIMATION

    return (
      <motion.div
        ref={informShortRef}
        id="short-inform"
        className="relative mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] -mt-[2.5rem] z-40"
        style={(flavours.length - 1) !== templateOrderIndex && questions.length > 1 ? { y: backgroundY, scale: scaleProgress, opacity: opacityProgress } : {}}
      >
        <div className="desktop:h-[150vh]">
          <div className={classNames(
            "desktop:sticky desktop:top-0 desktop:min-h-screen overflow-hidden mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]",
            {
              ["bg-white"]: version === "white",
              ["bg-grey-200"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}>
            <motion.div style={useMediaQuery('(min-width: 1280px)') ? { y: y } : {}}>
              <Grid
                testId="short-inform-theme2"
                customClasses={classnames(
                  "gap-0 h-full relative desktop:!mx-0 rounded-t-[3.125rem] -mt-[2.5rem]",
                  {
                    ["bg-white"]: version === "white",
                    ["bg-grey-200"]: version === "grey",
                    ["bg-grey-1000 text-white"]: version === "dark",
                  }
                )}
              >
                <div
                  id="short-inform-tagline-container"
                  className="mobile:pt-[2.625rem] tablet:pt-[2.625rem] mobile:pb-[2.25rem] tablet:pb-[1.5rem] desktop:pt-[5.25rem] desktop:pb-[2.25rem] desktop:col-start-2 col-span-full mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
                >
                  <h2
                    id="short-inform-tagline"
                    className={classnames(
                      "mobile:text-[1rem] desktop:text-lg font-medium uppercase",
                      {
                        ["!text-text-greyHeader"]: version === "white" || version === "grey",
                        ["!text-white"]: version === "dark",
                      }
                    )}
                  >
                    {query}
                  </h2>
                  {!content.header ? (
                    <TitleSkeleton rows={1} />
                  ) : (
                    <h3
                      id="short-inform-header"
                      className={classnames(
                        "mobile:pt-[36px] desktop:pt-[36px] mobile:text-4xl desktop:text-[40px] font-bold",
                        {
                          ["!text-text-greyHeader"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}
                    >
                      {content.header}
                    </h3>
                  )}
                </div>               
                <div
                  id="short-inform-header-content"
                  className="col-start-1 desktop:col-span-9 mobile:col-span-full desktop:min-h-[260px] h-full"
                >
                  <div
                    className={classnames(
                      "flex flex-col h-full w-full desktop:pr-[2] mobile:py-[1.5rem] desktop:py-[44px] grid grid-cols-9",
                      {
                        ["bg-grey-200 !text-greyText"]: version === "white",
                        ["bg-[#262626] text-text-white"]: version === "dark",
                        ["bg-white !text-greyText"]: version === "grey",
                      }
                    )}
                  >
                    <div className=" grid-col-span-4 col-start-2 col-span-full">
                      {!content.paragraphs[0] ? (
                        <ParagraphSkeleton />
                      ) : (
                        <TypewriterText
                          id="short-inform-paragraph-1"
                          className="text-[1.125rem] font-light pr-[28px]"
                          hasAllText={content.paragraphs[0].done}
                          text={content.paragraphs[0].text}
                          isDone={para1Done}
                          setIsDone={setPara1Done}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  id="short-inform-image-content"
                  className="mobile:col-start-1 desktop:col-start-10 w-full col-span-full desktop:min-h-[260px] h-full"
                >
                  <div className="mobile:col-start-1 w-full desktop:col-start-1 col-span-full desktop:col-end-13 desktop:row-start-1 h-full desktop:pb-0">
                    {!content.imgURL ? (
                      <ImageSkeleton display="short-inform" />
                    ) : (
                      <>
                        {!content.imgURL ? (
                          <div className="w-full desktop:min-h-[260px] h-full max-h-full bg-gradient-to-r from-cyan-500 to-blue-500"></div>
                        ) : (
                          <div className="relative w-full h-full">
                            <div
                              style={{ backgroundImage: `url('${content.imgURL}')` }}
                              className={`w-full mobile:min-h-[175px] tablet:min-h-[260px] h-full max-h-full bg-cover bg-center bg-no-repeat`}
                            ></div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Grid>
              <hr className="h-[28px] border-none" />
              <div data-testid="media-links-v2" className="hidden" />
              {para1Done && (
                <>
                  <Citations metadata={response.metadata} version={version} />
                  <div className="mt-[40px]"><MediaLinksV2 assets={assets} display="fullwidth" version={version} /></div>
                </>
              )}
            </motion.div>

            {/* <div className="mobile:hidden desktop:grid desktop:grid-cols-24 w-full absolute bottom-16">
              <div
                className={classNames(
                  "col-start-2 col-span-full",
                  {
                    ["desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible"]:
                      para1Done,
                    ["desktop:translate-y-full desktop:invisible desktop:h-0"]:
                      !para1Done,
                  }
                )}
              >
                {version === "white" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="white"
                  />
                )}
                {version === "dark" && (
                  <ButtonScrollDown customClasses="relative" versionColour="dark" />
                )}
                {version === "grey" && (
                  <ButtonScrollDown customClasses="relative" versionColour="grey" />
                )}
              </div>           
            </div> */}
          </div>
        </div>
        <div className={classNames({ ["!hidden"]: !para1Done })}>
          <motion.div
            ref={questionRef}
            style={useMediaQuery('(min-width: 1280px)') && para1Done && followUps.length > 0 ? { y: questionY } : {}}
            className={classNames("mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] w-full z-40 mobile:relative desktop:absolute mobile:-mt-[3.125rem] desktop:-mt-0",
              {
                ["hidden"]: hideQuestion,
              }
            )}>
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para1Done}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </div>
      </motion.div >
    );
  }
);
