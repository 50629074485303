import React from 'react';
import { Ref, forwardRef } from "react";
import { ContextMetadataItem } from "../../interface";
import ContentRailCarousel from './ContentRailCarousel';
import { EmblaOptionsType } from 'embla-carousel'

export const ContentRail = forwardRef(
  (
    {
      metadata,
    }: {
      metadata: ContextMetadataItem[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    if (!metadata || metadata.length === 0) {
      return null;
    }
console.log(metadata);
    const OPTIONS: EmblaOptionsType = { containScroll: false }    
    const SLIDES = metadata

    return (
      <div ref={ref}>
        <div className='tablet:hidden relative z-50 col-start-1 col-span-full bg-background-brand pt-[35px] pb-[100px]'>     
          <h3 className="text-lg text-white font-bold uppercase tablet:px-[50px] mobile:px-[22px] pb-[35px]">
            Based on your last question, we recommend the following pages
          </h3>   
            <ContentRailCarousel slides={SLIDES} options={OPTIONS} />      
        </div>
        <div        
          className="mobile:hidden tablet:block relative z-50 col-start-1 desktop:col-start-2 desktop:pl-[126px] pt-[35px] pb-[100px] tablet:pl-[50px] col-span-full bg-background-brand"
        >
          <h3 className="text-lg text-white font-bold uppercase ">
            Based on your last question, we recommend the following pages
          </h3>        
          <div className="flex overflow-y-auto no-scrollbar flex-row space-x-[28px] pt-[44px]">
            {metadata.map((item) => {
              return (
                <a
                  href={item.page_url}
                  target="_blank"
                  className="mx-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white"
                  aria-label={item.page_title}
                >
                  <div className="w-[263px]">
                    <div
                      style={{ backgroundImage: `url(${item.thumbnail})` }}
                      className="w-full h-[156px] min-w-[263px] bg-no-repeat bg-cover bg-center mb-8"
                      title={item.page_title}
                    />
                    <h3 className="text-lg text-text-contentRailTxt mb-2 font-bold">
                      {item.page_title}
                    </h3>
                  </div>
                </a>)
            })}
          </div>
        </div>
      </div>
    );
  }
);