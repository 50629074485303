import React from 'react';

export const Grid = ({
  children,
  customClasses,
  testId,
}: {
  children: React.ReactNode;
  customClasses: string;
  testId?: string;
}) => {
  return (
    <div
      id="grid"
      data-testid={testId}
      className={`grid mobile:grid-cols-8 tablet:grid-cols-12 desktop:grid-cols-24 z-40 ${customClasses}`}
    >
      {children}
    </div>
  );
};
