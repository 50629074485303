
import React, {
  Ref,
  useRef,
  useState,
  useEffect,
} from "react";
import { ShortInform, LongInform, XThingsInform } from "../../templates/Inform";
import {
  ShortInspire,
  LongInspire,
  XThingsInspire,
} from "../../templates/Inspire";
import { MovingSpinningCircles } from "../../components/Icons";
import { ShortHero, LongHero, XThingsHero } from "../../templates/Hero";
import { Preset } from "../../templates/Preset/Preset";
import { useChat } from "../../context/chat-context";
import { Flavour, Template } from "../../interface";
import Interim from "../Interim/Interim";
import { Cross, Minimise } from "../../components/Icons";
import { useNavigate } from "react-router-dom";
import { ContentRail, Fail } from "../../components";

import classnames from "classnames";
import { useConfig } from "../../context/config-context";
import { VideoHero } from "../../templates/Hero/VideoHero/VideoHero";
import Initial from "../Initial/Initial";

export default function Streaming() {
  const { config } = useConfig();
  const navigate = useNavigate();
  const {
    questions,
    askQuestion,
    giveFeedback,
    loading,
    latestMetadata,
    flavours,
    error,
    reset,
    resumed,
    setResumed,
  } = useChat();
  const ref = useRef(null);
  const [nextTemplate, setNextTemplate] = useState<Ref<HTMLDivElement>>(null);
  const [closeInterimAnimation, setcloseInterimAnimation] = useState(false);
  const [closeAnimationPhase1, setcloseAnimationPhase1] = useState(false);
  const [closeAnimationPhase2, setcloseAnimationPhase2] = useState(false);

  const handleClose = () => {
    setcloseAnimationPhase1(true);
    const promise1 = new Promise((resolve: any, reject: any) => {
      setTimeout(() => {
        setcloseAnimationPhase2(true);
        resolve();
      }, 100);
    });

    const promise2 = new Promise((resolve: any, reject: any) => {
      setTimeout(() => {
        setResumed(false);
        window.parent.postMessage("CLOSE", config.HOST);
        reset();
        navigate("/");
        resolve();
      }, 800);
    });

    Promise.all([promise1, promise2]).then(function () { });
  };

  const handleInterimClose = () => {
    setcloseInterimAnimation(true);
    setTimeout(() => {
      setResumed(false);
      window.parent.postMessage("CLOSE", config.HOST);
      reset();
      navigate("/");
    }, 800);

  };
  const handleMinimise = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, force: Boolean) => {
    if (e.target === document.getElementById("mainContainer") || force) {
      setcloseAnimationPhase1(true);
      const promise1 = new Promise((resolve: any, reject: any) => {
        setTimeout(() => {
          setcloseAnimationPhase2(true);
          resolve();
        }, 100);
      });

      const promise2 = new Promise((resolve: any, reject: any) => {
        setTimeout(() => {
          setResumed(true);
          window.parent.postMessage("CLOSE", config.HOST);
          navigate("/");
          resolve();
        }, 800);
      });

      Promise.all([promise1, promise2]).then(function () { });
    }
  };

  useEffect(() => {
    // Override the back button behavior
    const handleBackButtonClick = (event: PopStateEvent) => {
      event.preventDefault();
      window.parent.postMessage("CLOSE", config.HOST);
      navigate("/");
    };

    // Push a new state to the history stack to intercept the back button
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', handleBackButtonClick);

    return () => {
      window.removeEventListener('popstate', handleBackButtonClick);
    };
  }, []);

  return (
    <div
      id="mainContainer"
      className={classnames(
        "mainContainer h-full w-full font-default relative bg-black/50 backdrop-blur-sm",
        {
          "pt-[2.5%]": loading || questions.length,
          "desktop:px-[120px]": !closeInterimAnimation || !closeAnimationPhase1,
          "desktop:px-[30px] overflow-hidden": closeInterimAnimation || closeAnimationPhase1
        }
      )}
      ref={ref}
      onClick={(e) => {
        handleMinimise(e);
      }}
    >
      {questions.length > 0 && (
        <div className="z-50 sticky top-14 h-0 w-full ml-auto flex justify-end pr-4 mr-[100px]">
          {questions[0].response.template === "long" && (
            <div className="flex mr-[5px] desktop:mr-[-106px]">
              <button onClick={(e) => handleMinimise(e, true)}>
                <Minimise />
              </button>
              <button
                aria-label="Close"
                onClick={handleClose}
                className="focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white cursor-pointer ml-2"
              >
                <Cross crossColour="fill-icons-crosslight" />
              </button>

            </div>
          )}
          {questions[0].response.template === "short" && (
            <div className="flex mr-[5px]">
              <button onClick={(e) => handleMinimise(e, true)}>
                <Minimise />
              </button>
              <button
                aria-label="Close"
                onClick={handleClose}
                className="focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-black cursor-pointer ml-2"
              >
                <Cross crossColour="fill-icons-crossdark" />
              </button>
            </div>
          )}
          {questions[0].response.template === "three_parts" && (
            <div className="flex mr-[5px]">
              <button onClick={(e) => handleMinimise(e, true)}>
                <Minimise />
              </button>
              <button
                aria-label="Close"
                onClick={handleClose}
                className="focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white cursor-pointer ml-2"
              >
                <Cross crossColour="fill-icons-crosslight" />
              </button>
            </div>
          )}
          {questions[0].response.template !== "short" &&
            questions[0].response.template !== "long" &&
            questions[0].response.template !== "three_parts" && (
              <div className="flex mr-[5px]">
                <button onClick={(e) => handleMinimise(e, true)}>
                  <Minimise />
                </button>
                <button
                  aria-label="Close"
                  onClick={handleClose}
                  className="focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-black cursor-pointer ml-2"
                >
                  <Cross crossColour="fill-icons-crossdark" />
                </button>
              </div>
            )}
        </div>
      )}

      {!questions.length && (
        <div className="h-screen w-full relative">
          <div
            className={classnames(
              'mobile:rounded-t-[1.5rem] tablet:rounded-t-[1.5rem] desktop:rounded-t-[1.5rem] !fixed !bottom-0 w-full desktop:w-[calc(100%-240px)]',
              {
                'mobile:h-full smallscreenheight:h-screen tablet:h-[550px]': !questions.length && !loading,
                'h-full transition-all duration-700 ease-in relative flex justify-center items-center bg-black opacity-80 backdrop-blur-md mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]':
                  !questions.length && loading,
                'transition-all duration-700 ease-in !w-[114px] !h-[78px] !rounded-[3.125rem] bg-black opacity-80 backdrop-blur-md desktop:mb-[30px] tablet:mb-[44px] mobile:mb-[49px]': closeInterimAnimation
              }
            )}
          >
            {closeInterimAnimation && (
              <div className="flex justify-center items-center h-full w-full">
                <div className="animate-disappear">
                  <MovingSpinningCircles />
                </div>
              </div>
            )}
            {!closeInterimAnimation && (
              <>
                {!questions.length && !loading &&
                  <div className="relative mobile:h-screen smallscreenheight:h-screen tablet:h-[550px] mobile:rounded-t-[1.5rem] tablet:rounded-t-[1.5rem] desktop:rounded-t-[1.5rem] bg-black opacity-80 backdrop-blur-md">
                    <div className="z-50 absolute mobile:right-4 mobile:top-4 tablet:right-8 tablet:top-6 desktop:right-[2.5%] desktop:pt-2">
                      <div className="flex">
                        {/* <button onClick={(e) => handleMinimise(e, true)}>
                          <Minimise />
                        </button> */}
                        <button
                          aria-label="Close"
                          onClick={handleInterimClose}
                          className="smallscreenheight:pt-[10px] focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white cursor-pointer ml-2"
                        >
                          <Cross crossColour="fill-icons-crosslight" />
                        </button>
                      </div>
                    </div>
                    <Interim onSubmit={askQuestion} />
                  </div>
                }
                {!questions.length && loading && (
                  <div className="ml-[17px]">
                    <MovingSpinningCircles />
                  </div>
                )}
              </>
            )}
          </div>
          {/* {!questions.length && !loading && 
            <Interim onSubmit={askQuestion} />
          }  */}
        </div>
      )}

      <div className={classnames('h-full w-full relative',
        {
          '!h-screen flex items-left justify-left': closeAnimationPhase1 && !closeAnimationPhase2 || closeAnimationPhase1 && closeAnimationPhase2,
        }
      )}>
        <div className={classnames('',
          {
            'h-full w-full': !closeAnimationPhase1,
            'h-screen w-full mobile:rounded-t-[3.125rem] desktop:rounded-t-[1.5rem] bg-black':
              closeAnimationPhase1 && !closeAnimationPhase2,
            'transition-all duration-700 ease-in absolute bottom-0 w-[114px] h-[78px] mobile:rounded-[3.125rem] bg-black opacity-80 backdrop-blur-md desktop:mb-[30px] tablet:mb-[44px] mobile:mb-[49px]': closeAnimationPhase1 && closeAnimationPhase2
          }
        )}>
          {closeAnimationPhase1 && (
            <div className="flex justify-center items-center h-full w-full">
              <div className="animate-disappear">
                <MovingSpinningCircles />
              </div>
            </div>
          )}
          {!closeAnimationPhase1 && (
            <div>
              {error && (
                <Fail
                  failText="Sorry, something went wrong. Please try again later."
                  templateOrderIndex={0}
                  loading={loading}
                  askQuestion={askQuestion}
                  giveFeedback={giveFeedback}
                  setNextTemplate={setNextTemplate}
                  nextTemplate={nextTemplate}
                  flavours={flavours}
                />
              )}
              {questions.map((question, i) => {
                switch (flavours[i]) {
                  case Flavour.fail: {
                    return (
                      <Fail
                        key={i}
                        failText={
                          question.response?.content.paragraphs[0]?.text as string
                        }
                        templateOrderIndex={i}
                        loading={loading}
                        askQuestion={askQuestion}
                        giveFeedback={giveFeedback}
                        setNextTemplate={setNextTemplate}
                        nextTemplate={nextTemplate}
                        flavours={flavours}
                      />
                    );
                  }
                  case Flavour.hero: {
                    switch (question.response.template) {
                      case Template.preset_answer: {
                        return (
                          <Preset
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      case Template.short: {
                        return (
                          <ShortHero
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      case Template.long: {
                        return (
                          <LongHero
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      case Template.three_parts: {
                        return (
                          <XThingsHero
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response!}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      case Template.video: {
                        return (
                          <VideoHero
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      default: {
                        return;
                      }
                    }
                  }
                  case Flavour.inform: {
                    switch (question.response.template) {
                      case Template.short: {
                        return (
                          <ShortInform
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      case Template.long: {
                        return (
                          <LongInform
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      case Template.three_parts: {
                        return (
                          <XThingsInform
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      default: {
                        return;
                      }
                    }
                  }
                  case Flavour.inspire: {
                    switch (question.response.template) {
                      case Template.short: {
                        return (
                          <ShortInspire
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      case Template.long: {
                        return (
                          <LongInspire
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      case Template.three_parts: {
                        return (
                          <XThingsInspire
                            templateOrderIndex={i}
                            loading={loading}
                            response={question.response}
                            askQuestion={askQuestion}
                            giveFeedback={giveFeedback}
                            key={i}
                            setNextTemplate={setNextTemplate}
                            nextTemplate={nextTemplate}
                            flavours={flavours}
                            questions={questions}
                          />
                        );
                      }
                      default: {
                        return;
                      }
                    }
                  }
                }
              })}
              {latestMetadata.length ? (
                <ContentRail metadata={latestMetadata} />
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
