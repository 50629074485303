import React from 'react'
import { EmblaOptionsType } from 'embla-carousel'
import {
  PrevButton,
  NextButton,
  usePrevNextButtons
} from './ContentRailCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'

type PropType = {
  slides: any;
  options?: EmblaOptionsType
}

const ContentRailCarousel: React.FC<PropType> = (props) => {
  const { slides, options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  return (
    <section className="max-w-[48rem] m-auto relative">
      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex touch-pan-y touch-pinch-zoom ml-[calc(28px*-1)]">
        {slides.map((item: any, index: number) => {          
            return (
              <a className="embla__slide min-w-0 pl-[1rem]" key={item.page_title} href={item.page_url} target="_blank" aria-label={item.page_title}>            
                <div
                  style={{ backgroundImage: `url(${item.thumbnail})` }}
                  className="w-full h-[156px] min-w-[263px] bg-no-repeat bg-cover bg-center mb-8"
                  title={item.page_title}
                />
                <h3 className="text-lg text-text-contentRailTxt mb-2 font-bold">
                  {item.page_title}
                </h3>                              
              </a>
            )
          })}          
        </div>
      </div>
      <div className='absolute top-12 left-0'>
        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
      </div>
      <div className='absolute top-12 right-0'>
        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
      </div>      
    </section>
  )
}

export default ContentRailCarousel
