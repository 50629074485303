import React from 'react';
import classnames from "classnames";
import { VideoIcon } from '../Icons/VideoIcon';
import { PlayIcon, VideoClip } from '../Icons';

interface PdfMeta {
  sentence: string;
  title: string;
}

interface VideoData {
  metadata: PdfMeta;
}

interface MediaAssets {
  video: VideoData[];
}

export const Video = ({
  url,
  onClick,
  name,
  version,
  assets,
}: {
  url?: string;
  onClick?: () => void;
  name?: any;
  version: string;
  assets: MediaAssets;
}) => {

  return (
    <div className="flex w-full space-x-8">
      <div className='bg-video-image desktop:h-[116px] mobile:h-[90px] mobile:w-[250px] desktop:w-[296px] bg-contain bg-no-repeat flex justify-center items-center'>
        <PlayIcon />
      </div>
      <div className="flex flex-col w-full">
        <div
          className={classnames(
            "font-semibold pb-2 mobile:!text-sm tablet:!text-sm",
            {
              ["!text-text-greyText"]: version === "white" || version === "grey" || version === "light",
              ["!text-white"]: version === "dark",
            }
          )}
        >
          {assets?.video[0]?.metadata?.title}
        </div>
        <div className="flex flex-col justify-between h-full">
          <div
            className={classnames(
              "pb-2 text mobile:!text-sm tablet:!text-sm",
              {
                ["!text-text-greyText"]: version === "white" || version === "grey" || version === "light",
                ["!text-white"]: version === "dark",
              }
            )}
          >
            {assets?.video[0]?.metadata?.sentence}
          </div>
          <a
            href={url}
            target="_blank"
            className="flex mt-3 font-bold text-sm items-center focus-visible:outline-none focus-visible:ring-2 focus-visible:rounded focus-visible:ring-white cursor-pointer"
            aria-label="You might be interested in our video"
          >
            <p
              className={classnames(
                "pr-2",
                {
                  ["!text-text-greyText"]: version === "white" || version === "grey" || version === "light",
                  ["!text-white"]: version === "dark",
                }
              )}
            >
              View
            </p>
            {version === "dark" ? (
              <VideoClip colour="fill-white" />
            ) : (
              <VideoClip colour="fill-[#595959]" />
            )
            }
          </a>
        </div>
      </div>
    </div>
  );
};
