import React, { MutableRefObject, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Grid, TypewriterText } from "../";
import { QuestionBoxFullWidth } from "../QuestionBox/QuestionBoxFullWidth";
import { useChat } from "../../context/chat-context";
import classNames from "classnames";
import { motion, useInView, useScroll, useTransform } from "framer-motion"
import { Flavour } from "../../interface";
import { useConfig } from "../../context/config-context";


export const Fail = forwardRef(
  (
    {
      failText,
      templateOrderIndex,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
      flavours
    }: {
      failText: string;
      templateOrderIndex: number;
      loading: boolean;
      setNextTemplate: React.Dispatch<React.SetStateAction<Ref<HTMLDivElement>>>;
      nextTemplate: Ref<HTMLDivElement>;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      flavours: Flavour[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const { questions } = useChat();
    const { config } = useConfig();
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const [failTextDone, setFailTextDone] = useState(false);
    const [hideQuestion, setHideQuestions] = useState(false);
    const failRef = useRef(null);
    const questionRef = useRef<null | HTMLDivElement>(null);
    const questionIsInView = useInView(questionRef, { once: true })

    useEffect(() => {
      setNextTemplate(failRef)
    }, [])

    let lastFollowUps;
    if (questions[0].response.template === "safety_fail") {
      lastFollowUps = config.INITIAL_QUESTIONS
    } else {
      lastFollowUps =
        [...questions].reverse().find((q) => q.response?.followUps.length)?.response
          ?.followUps || [];
    }

    const userQuestion = [...questions]
      .reverse()
      .find((q) => q.response?.followUps.length)?.response?.userQuestion;

    const { scrollYProgress } = useScroll({
      target: failRef,
      offset: ["center end", "end start"],
      // offset: ["center end", "end center"],
    });

    const opacityProgress = useTransform(scrollYProgress, [1, 0.6], [0.6, 1]);
    const scaleProgress = useTransform(scrollYProgress, [1, 0.9], ["90%", "100%"]);
    const backgroundY = useTransform(scrollYProgress, [0.8, 1], ["0%", "50%"]);

    return (
      <motion.div
        ref={failRef}
        id="fail"
        className="relative"
        style={(flavours.length - 1) !== templateOrderIndex && questions.length > 1 ? { y: backgroundY, scale: scaleProgress, opacity: opacityProgress } : {}}
      >
        <Grid
          customClasses={classNames("bg-white desktop:!mx-0 gap-x-4 h-full relative pt-[30px] mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]", {
            ["-mt-[2.5rem]"]: templateOrderIndex !== 0,
          })}
        >
          <div data-testid="fail" className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-24 desktop:pt-[84px] pt-[42px] desktop:pb-[30px] pb-[36px] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0">
            <h2
              id="long-hero-tagline"
              className="mobile:text-base desktop:text-xl font-extrabold uppercase"
            >
              This query has failed
            </h2>
          </div>
          <div
            id="long-hero-subheading-content"
            className="mobile:col-start-1 desktop:col-start-2 mobile:col-span-full desktop:col-end-24 desktop:pb-[134px] pb-[72px] mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0"
          >
            <p id="fail-message">
              <TypewriterText
                id="long-hero-paragraph-2"
                className="text-2xl font-light"
                text={failText}
                hasAllText
                isDone={failTextDone}
                setIsDone={setFailTextDone}
              />
            </p>
          </div>
          <motion.div
            ref={questionRef}
            id="question-container"
            style={failTextDone && questionIsInView ? { y: "0%", transition: "all 300ms ease-in" } : { y: "100%", visibility: "hidden" }}
            className={classNames(
              "col-span-full mobile:-mt-[1.875rem] desktop:-mt-[3.125rem] z-40 relative",
              {
                ["hidden"]: hideQuestion,
              }
            )}
          >
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={lastFollowUps}
              isLoading={!failTextDone}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </Grid>
      </motion.div>
    );
  });
