import classNames from "classnames";
import { AssetsData } from "../../interface";
import { Grid } from '../Grid';
import { Video } from '../Video/video';
import { Pdf } from '../Pdf/pdf';

export default function MediaLinksV2({ assets, display = "fullwidth", version }: { assets: AssetsData; display?: "fullwidth" | "block"; version: string }) {
  const videos =
    (assets?.video || [])
      .filter((item) => item.relevance_score > 1)
      .sort((a, b) => b.relevance_score - a.relevance_score);

  const pdfs =
    (assets?.pdf || [])
      .filter((item) => item.relevance_score > 1)
      .sort((a, b) => b.relevance_score - a.relevance_score);

  if (!videos.length && !pdfs.length) {
    return null;
  }

  if (pdfs[0].relevance_score < 1.71 && videos[0].relevance_score < 1.71) return;

  return (
    <div className={classNames(videos.length || pdfs.length ? "mobile:pb-[4.5rem]" : "")}>
      {display === "fullwidth" && (
        <Grid customClasses="gap-x-4 w-full flex mobile:flex-col desktop:flex-row ">
          <h2
            id="long-hero-tagline"
            className={classNames(
              "mobile:col-start-1 mobile:col-span-full desktop:col-start-2 mobile:text-[16px] row-start-1 pb-[1.875rem] desktop:text-lg font-medium uppercase mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0",
              {
                ["text-black"]: version === "white" || version === "grey",
                ["text-white"]: version === "dark",
              }
            )}
          >
            Check out these additional references for more insights:
          </h2>
          {pdfs.length > 0 && videos.length > 0 && pdfs[0].relevance_score >= 1.71 && (
            <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 mobile:row-start-2 desktop:row-start-2 desktop:col-span-9 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0">
              <div className={classNames(
                "py-8 mobile:border-t-2 desktop:border-y-[1px] desktop:min-h-[216px] space-x-",
                {
                  ["mobile:border-[#e5e7eb] text-black"]: version === "white" || version === "grey",
                  ["mobile:border-white/20 text-white"]: version === "dark",
                }
              )}>
                <Pdf url={pdfs[0].url} assets={assets} name={pdfs[0].metadata?.name} version={version}></Pdf>
              </div>
            </div>
          )}

          {pdfs.length > 0 && videos.length <= 0 && pdfs[0].relevance_score >= 1.71 && (
            <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 mobile:row-start-2 desktop:row-start-2 desktop:col-span-12 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0">
              <div className={classNames(
                "py-8 border-y-2 space-x-1",
                {
                  ["border-black/20 text-black"]: version === "white" || version === "grey",
                  ["border-white/20 text-white"]: version === "dark",
                }
              )}>
                <Pdf url={pdfs[0].url} assets={assets} name={pdfs[0].metadata?.name} version={version}></Pdf>
              </div>
            </div>
          )}

          {videos.length > 0 && pdfs.length > 0 && videos[0].relevance_score >= 1.71 && (
            <div
              className={classNames(
                "mobile:col-start-1 mobile:col-span-full desktop:col-start-12 desktop:col-span-12 mobile:row-start-3 desktop:row-start-2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0",
                {
                  ["desktop:col-start-2 desktop:col-span-12"]: pdfs[0]?.relevance_score <= 1.71
                }
              )}
            >
              <div className={classNames(
                "py-8 mobile:border-t-1 desktop:border-y-[1px] space-x-8 desktop:min-h-[216px]",
                {
                  ["mobile:border-black/20 text-black"]: version === "white",
                  ["mobile:border-white/20 text-white"]: version === "dark",
                }
              )}>
                <Video url={videos[0].url} assets={assets} name={videos[0].metadata?.name} version={version} ></Video>
              </div>
            </div>
          )}

          {videos.length > 0 && pdfs.length <= 0 && videos[0].relevance_score >= 1.75 && (
            <div className="mobile:col-start-1 mobile:col-span-full desktop:col-start-2 desktop:col-span-12 mobile:row-start-2 desktop:row-start-2 mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0">
              <div className={classNames(
                "py-8 border-y-2 space-x-8",
                {
                  ["border-black/20 text-black"]: version === "white" || version === "grey",
                  ["border-white/20 text-white"]: version === "dark",
                }
              )}>

              </div>
            </div>
          )}
        </Grid>
      )}
      {display === "block" && (
        <div className="w-full flex flex-col mobile:py-[4.5rem] desktop:pt-[2.5rem] desktop:pb-0">
          <h2
            id="long-hero-tagline"
            className={classNames(
              "mobile:col-start-1 mobile:col-span-full desktop:col-start-2 mobile:text-base row-start-1 pb-[1.875rem] desktop:text-lg font-medium uppercase mobile:px-[1.375rem] tablet:px-[2.75rem] desktop:px-0",
              {
                ["text-black"]: version === "white" || version === "grey",
                ["text-white"]: version === "dark",
              }
            )}
          >
            Check out these additional references for more insights:
          </h2>

          {pdfs.length > 0 && videos.length > 0 && (
            <div className={classNames(
              "py-8 border-t-1 space-x-8",
              {
                ["mobile:border-black/20 text-black"]: version === "white" || version === "grey",
                ["mobile:border-white/20 text-white"]: version === "dark",
              }
            )}>
              <Pdf url={pdfs[0].url} assets={assets} name={pdfs[0].metadata?.name} version={version}></Pdf>
            </div>
          )}

          {pdfs.length > 0 && videos.length <= 0 && (
            <div className={classNames(
              "py-8 border-y-2 space-x-8",
              {
                ["mobile:border-black/20 text-black"]: version === "white" || version === "grey",
                ["mobile:border-white/20 text-white"]: version === "dark",
              }
            )}>

            </div>
          )}

          {videos.length > 0 && pdfs.length > 0 && (
            <div className={classNames(
              "py-8 border-t-1 space-x-8",
              {
                ["mobile:border-black/20 text-black"]: version === "white" || version === "grey",
                ["mobile:border-white/20 text-white"]: version === "dark",
              }
            )}>

            </div>
          )}

          {videos.length > 0 && pdfs.length <= 0 && (
            <div className={classNames(
              "py-8 border-y-1s space-x-8",
              {
                ["mobile:border-black/20 text-black"]: version === "white" || version === "grey",
                ["mobile:border-white/20 text-white"]: version === "dark",
              }
            )}>

            </div>
          )}
        </div>
      )}
    </div>
  );
}