import React, {
  MutableRefObject,
  Ref,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { Grid } from "../../../components";
import { Flavour, Question, QuestionResponse } from "../../../interface";
import { TitleSkeleton, ParagraphSkeleton } from "../../../components/Skeleton";
import classnames from "classnames";
import Citations from "../../../components/Citations/Citations";
import { QuestionBoxFullWidth } from "../../../components/QuestionBox/QuestionBoxFullWidth";
import { ButtonScrollDown } from "../../../components/Button/ButtonScrollDown";
import TypewriterText from "../../../components/TypewriterText/TypewriterText";
import classNames from "classnames";
import {
  motion,
  useScroll,
  useTransform,
  useInView,
  circOut,
  cubicBezier,
  useMotionValueEvent,
} from "framer-motion";
import MediaLinksV2 from "../../../components/MediaLinks/MediaLinksV2";
import useMediaQuery from "../../../hooks/useMediaQuery";

export const LongInform = forwardRef(
  (
    {
      templateOrderIndex,
      response,
      loading,
      askQuestion,
      giveFeedback,
      setNextTemplate,
      nextTemplate,
      questions,
      flavours,
    }: {
      templateOrderIndex: number;
      loading: boolean;
      response: QuestionResponse;
      askQuestion: (query: string) => void;
      giveFeedback?: (feedback_type: -1 | 1) => Promise<void>;
      setNextTemplate: React.Dispatch<
        React.SetStateAction<Ref<HTMLDivElement>>
      >;
      nextTemplate: Ref<HTMLDivElement>;
      questions: Question[];
      flavours: Flavour[];
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const [version, setVersion] = useState("white");
    const { content, followUps, userQuestion, query, assets } = response;
    const [para1Done, setPara1Done] = useState(false);
    const [para2Done, setPara2Done] = useState(false);
    const [hideQuestion, setHideQuestions] = useState(false);
    const nextTemplateRef = nextTemplate as MutableRefObject<HTMLDivElement>;
    const informLongRef = useRef(null);
    const questionRef = useRef<null | HTMLDivElement>(null);
    const questionIsInView = useInView(questionRef)

    useEffect(() => {
      setNextTemplate(informLongRef);
      // TODO - I need to simplyfy and allow more incase there are more than 11 templates in an experience
      if (
        templateOrderIndex === 0 ||
        templateOrderIndex === 3 ||
        templateOrderIndex === 6 ||
        templateOrderIndex === 9
      ) {
        setVersion("white");
      }
      if (
        templateOrderIndex === 1 ||
        templateOrderIndex === 4 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 10
      ) {
        setVersion("dark");
      }
      if (
        templateOrderIndex === 2 ||
        templateOrderIndex === 5 ||
        templateOrderIndex === 7 ||
        templateOrderIndex === 11
      ) {
        setVersion("grey");
      }
    }, []);

    console.log(
      "template: Long Inform",
      `height: ${document.getElementById("long-inform")?.getBoundingClientRect().height}`,
      `width: ${document.getElementById("long-inform")?.getBoundingClientRect().width}`
    );

    //ANIMATION
    const { scrollYProgress: mainYProgress } = useScroll({
      target: informLongRef,
      offset: ["start center", "end start"],
      // offset: ["center end", "end center"],
    });

    const opacityProgress = useTransform(mainYProgress, [1, 0.6], [0.6, 1]);
    const scaleProgress = useTransform(mainYProgress, [1, 0.6], ["90%", "100%"]);
    const backgroundY = useTransform(mainYProgress, [0.6, 1], ["0%", "30%"]);

    const { scrollYProgress: contentYProgress } = useScroll({
      target: informLongRef,
    });
    const { scrollYProgress: questionYProgress } = useScroll({
      target: informLongRef,
      offset: ["center end", "end center"],
    });

    const y = useTransform(contentYProgress, [0, 1], ["0", document.body.clientHeight < 2200 ? "-60%" : "-40%"]);
    const questionY = useTransform(questionYProgress, [1, 0], ["-100%", "0%"]);
    // const questionY = useTransform(contentYProgress, [1, 0], ["-100%", "100%"]);
    // END ANIMATION

    return (
      <div className="relative z-40">
        <motion.div
          ref={informLongRef}
          data-testid="long-inform-container"
          id="long-inform"
          className={classnames(
            "relative desktop:h-[200vh] mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] -mt-[2.5rem] z-40",
            {
              ["bg-white text-text-greyText"]: version === "white",
              ["bg-grey-200 text-text-greyText"]: version === "grey",
              ["bg-grey-1000 text-white"]: version === "dark",
            }
          )}
          style={(flavours.length - 1) !== templateOrderIndex && questions.length > 1 ? { y: backgroundY, scale: scaleProgress, opacity: opacityProgress } : {}}
        >
          <div className="desktop:sticky desktop:top-0 desktop:h-screen overflow-hidden mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]">
            <motion.div style={useMediaQuery('(min-width: 1280px)') ? { y: y } : {}}>
              <Grid
                customClasses={classnames(
                  "gap-x-4 desktop:!mx-0 relative mobile:rounded-t-3xl desktop:rounded-t-[1.5rem]",
                  {
                    ["bg-white !text-text-greyText"]: version === "white",
                    ["bg-grey-200 !text-text-greyText"]: version === "grey",
                    ["bg-grey-1000 text-white"]: version === "dark",
                  }
                )}
              >
                <div
                  id="long-inform-header-content"
                  className="mobile:col-start-1 desktop:col-start-2 col-span-full mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0"
                >
                  <h2
                    id="long-inform-tagline"
                    className={classnames(
                      "mobile:pt-[2.625rem] tablet:pt-[2.625rem] desktop:pt-[5.25rem] mobile:text-[1rem] desktop:text-lg font-medium uppercase",
                      {
                        ["!text-text-greyHeader"]: version === "white" || version === "grey",
                        ["!text-white"]: version === "dark",
                      }
                    )}
                  >
                    {query}
                  </h2>
                  {!content.header ? (
                    <TitleSkeleton rows={1} />
                  ) : (
                    <h3
                      id="long-inform-header"
                      className={classnames(
                        "mobile:pt-[36px] desktop:pt-[36px] mobile:text-4xl desktop:text-[40px] font-bold",
                        {
                          ["!text-text-greyHeader"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}
                    >
                      {content.header}
                    </h3>
                  )}
                </div>

                <div
                  id="long-inform-image-content"
                  className="mobile:col-start-1 desktop:col-start-2 desktop:col-span-6 mobile:col-span-full mobile:pt-[2.25rem] desktop:pt-[1.375rem] desktop:flex desktop:flex-col mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0"
                >
                  {!content.tagline ? (
                    <ParagraphSkeleton lines={6} />
                  ) : (
                    <p
                      id="long-inform-paragraph-1"
                      className={classnames(
                        "mobile:text-xl desktop:text-2xl font-light",
                        {
                          ["!text-text-greyHeader"]: version === "white" || version === "grey",
                          ["!text-white"]: version === "dark",
                        }
                      )}
                    >
                      {content.tagline}
                    </p>
                  )}
                </div>
                <div
                  id="long-inform-answer-content"
                  className="mobile:col-start-1 desktop:col-start-9 mobile:col-span-full desktop:col-end-24 mobile:pt-[36px] desktop:pt-[1.375rem] mobile:mx-[1.375rem] tablet:mx-[2.75rem] desktop:mx-0 desktop:pb-[2.5rem]"
                >
                  {!content.paragraphs[0] ? (
                    <ParagraphSkeleton lines="default" classNames="mb-[1.375rem]" />
                  ) : (
                    <TypewriterText
                      id="long-inform-paragraph-2"
                      className="mobile:text-sm desktop:text-lg font-light pb-[1.375rem]"
                      hasAllText={content.paragraphs[0].done}
                      text={
                        content.paragraphs[0].text[
                          content.paragraphs[0].text.length - 1
                        ] === "."
                          ? content.paragraphs[0].text
                          : `${content.paragraphs[0].text}.`
                      }
                      isDone={para1Done}
                      setIsDone={setPara1Done}
                    />
                  )}

                  {!content.paragraphs[1] ? (
                    <ParagraphSkeleton lines="tablet" />
                  ) : (
                    para1Done && (
                      <TypewriterText
                        id="long-inform-paragraph-3"
                        className="mobile:text-sm desktop:text-lg font-light"
                        hasAllText={content.paragraphs[1].done}
                        text={content.paragraphs[1].text}
                        isDone={para2Done}
                        setIsDone={setPara2Done}
                      />
                    )
                  )}

                </div>
              </Grid>
              {para2Done && <Citations metadata={response.metadata} version={version} />}
              {para2Done && <MediaLinksV2 assets={assets} display="fullwidth" version={version} />}
            </motion.div>
            {/* <div className="mobile:hidden desktop:grid desktop:grid-cols-24 desktop:absolute bottom-16 w-full">
              <div
                className={classNames(
                  "desktop:col-start-2 desktop:col-span-full",
                  {
                    ["desktop:translate-y-0 desktop:transition-all desktop:duration-300 desktop:delay-100 desktop:visible"]:
                      para2Done,
                    ["desktop:translate-y-full desktop:invisible desktop:h-0"]:
                      !para2Done,
                  }
                )}
              >
                {version === "white" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="white"
                  />
                )}
                {version === "dark" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="dark"
                  />
                )}
                {version === "grey" && (
                  <ButtonScrollDown
                    customClasses="relative"
                    versionColour="grey"
                  />
                )}
              </div>
            </div> */}
          </div>
        </motion.div>
        <div className={classNames({ ["!hidden"]: !para2Done })}>
          <motion.div
            ref={questionRef}
            id="question-container"
            style={useMediaQuery('(min-width: 1280px)') && para2Done && followUps.length > 0 ? { y: questionY } : {}}
            //style={para2Done && questionIsInView ? {y: "0%", transition: "all 300ms ease-in"} : {y: "100%", visibility: "hidden"}}             
            className={classNames(
              "w-full mobile:relative desktop:absolute z-40 mobile:rounded-t-3xl desktop:rounded-t-[1.5rem] mobile:-mt-[3.125rem] desktop:-mt-0",
              {
                ["hidden"]: hideQuestion,
              }
            )}>
            <QuestionBoxFullWidth
              templateOrderIndex={templateOrderIndex}
              style="lightTxt"
              backgroundColour="background-brand"
              followupQuestions={followUps}
              isLoading={!para2Done}
              onSubmit={askQuestion}
              giveFeedback={giveFeedback}
              optionQuestion={userQuestion}
              nextTemplate={nextTemplateRef}
              sethideQuestion={setHideQuestions}
              customMargin="!mx-0"
            />
          </motion.div>
        </div>
      </div>
    );
  }
);
